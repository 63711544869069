import React, { Component } from 'react';

export default class Product2 extends Component{
  render() {
    return(
      <div className="product product-2">
        <div id="sip" className="anchor-link"></div>
        <div className="product--about">
          <img alt="" src="/images/products/sip.svg"/>
          <h2>Есть контакт!</h2>
          <p>
            Спасательный круг в сфере IP-телефонии. В случае обрыва связи между удаленными объектами и телефонными серверами, система сразу берёт на себя дальнейшую работу до полного устранения неполадок.
          </p>
          <div className="anchors-container">
            <a href="#sip-description">Описание</a>
            <a href="#sip-functions">Возможности</a>
            <a href="#sip-specs">Спецификации</a>
          </div>
        </div>

        <div className="product--section">
          <div id="sip-description" className="anchor-link"></div>
          <h2>Описание</h2>
          <div className="grid">
            <div>
              <h3>Зачем это нужно?</h3>
              <p>
                Для надёжности. У централизации ресурсов облачных сервисов множество преимуществ, однако в случае недоступности серверов, PBX сервис исчезает полностью. Нельзя сделать звонок даже в соседнюю комнату. SSG (Smart SIP Gateway) обеспечивает пользователя базовыми сервисами телефонии на все время устранения аварии а так же облегчает ежедневное администрирование и поддержку.
              </p>
            </div>
            <div>
              <h3>Больше — лучше</h3>
              <p>
              SSG может работать как в единственном экземпляре, так и в кластере, повышая тем самым  уровень надежности предоставления услуги. Установка кластера SSG позволит обновить ПО или произвести перенос вообще без перерыва связи. Переключение трафика между узлами кластера происходит без обрыва текущих звонков.
              </p>
            </div>
          </div>
        </div>

        <div className="product--section">
          <div id="sip-functions" className="anchor-link"></div>
          <h2>Возможности</h2>
          <div className="grid">
            <div>
              <h3>Обеспечение сервисов</h3>
              <p>
                DHCP, DNS, NTP;
              </p>
            </div>
            <div>
              <h3>Поддержка VPN-протоколов</h3>
              <p>
                PPTP и L2TP;
              </p>
            </div>
            <div>
              <h3>Траблшуттинг</h3>
              <p>
              Быстрое и эффективное устранение возникающих сбоев в сети, благодаря набору инструментов.*
              </p>
            </div>
            <div>
              <h3>Поддержка TLS/SRTP</h3>
              <p>
              Позволяет обеспечивать отказоустойчивость даже при использовании шифрованной связи.
              </p>
            </div>
            <div>
              <h3>Хранение необходимых данных</h3>
              <p>
               Локальную память устройства можно использовать для хранения конфигураций абонентских устройств и ПО, что особенно акту-ально, если применяются спутниковые каналы связи.
              </p>
            </div>
            <div>
              <h3>Сохранение CDR</h3>
              <p>
              По всем звонкам, совершённым за время недоступности серверов телефонии. Вы можете проанализировать их с помощью вашей системы биллинга и корректно формировать счета.
              </p>
            </div>
            <div>
              <h3>Какие сервисы предоставляются?</h3>
              <p>
              Пока серверы PBX недоступны, SSG обеспечивает на удалённом объекте базовые телефонные сервисы, такие как: вызов, перевод, удержание, музыка на удержании и конференции.

              Автоматическое формирование номерного плана**, маршрутизацию вызовов между аналогичными устройствами или шлюзами в сеть общего пользования. Устройство обеспечивает от 15 до 350 одновременных вызовов, что определяется лицензионным ключом.
              </p>
            </div>
            <div className="double-span">
              <span>*По аналогии с Wireshark;</span>
              <span>** При работе в составе Broadworks;</span>
            </div>
          </div>
        </div>

        <div className="product--section">
          <div id="sip-specs" className="anchor-link"></div>
          <h2>Спецификации</h2>
          <div className="grid-specs">
            <div>
              <h3>Стандарты передачи данных</h3>
              <p>
                IEEE 802.3 (Ethernet), IPv4, ARP, STP,  ICMP, TCP, UDP, TLS, 802.1Q (VLAN);
              </p>
            </div>
            <div>
              <h3>Сетевые сервисы</h3>
              <p>
                DHCP, DNS, SNTP, PPTP. (RFC2637), L2TP/IPSec, FTP/TFTP server, ACL;
              </p>
            </div>
            <div>
              <h3>Сигнализация</h3>
              <p>
                SIP v2 (RCF 3261);
              </p>
            </div>
            <div>
              <h3>Медиа-данные</h3>
              <p>
                SDP (RFC 2327), RTP/RTCP (RFC 3550), DiffServ, QoS, ToS, DTMF (RFC 2833), SRTP;
              </p>
            </div>
            <div>
              <h3>Управление/ Администрирование</h3>
              <p>
                Web Management (Via HTTP/HTTPS), SNMPv2, Syslog (RFC 3164), DUMP;
              </p>
            </div>
            <img alt="" src="/images/products/sip_specs.svg" />
            <div>
              <h3>WAN</h3>
              <p>
                WAN/LAN 10/100/1000<br/> Base T
              </p>
            </div>
            <div>
              <h3>LAN</h3>
              <p>
                3.0 Type-A
              </p>
            </div>
            <div>
              <h3>USB</h3>
              <p>
                SSG&#60;10 Вт
              </p>
            </div>
          </div>
        </div>

      </div>
    )
  }
}
