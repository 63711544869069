import React from 'react';
import { withRouter } from 'react-router-dom';
import './App.scss';

import Header from './components/header.component';
import Footer from './components/footer.component';
import Main from './components/main.component';

function App(props) {

  const {pathname} = props.location

  return (
    <div className="App">
      <Header/>
      <div id="video-wrapper" className="bg-video-wrapper">
      </div>
      <Main className={ pathname.replace('/', '') }/>
      <Footer/>
    </div>
  );
}

export default withRouter(App);
