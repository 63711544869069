import React, { Component } from 'react';

export default class Footer extends Component{
  render() {
    return(
      <footer id="footer">
        <ul className="contact-info">
          <li><a href="tel:+7 495 369 24 22">+7 (495) 369 24 22</a></li>
          <li><a href="mailto:questions@wsl.group">Questions@wsl.group</a></li>
          <li>ВАЙТ СЭЙЛЗ ГРУПП</li>
        </ul>
        <img alt="" src="/images/writing.svg" className="writing"/>
      </footer>
    )
  }
}
