import React, { Component } from 'react';
import Form from './form/form.component';

export default class Connect extends Component{
  render() {
    return(
      <section id="connect">
        <div className="logo">
          <img alt="" src="/images/ship.svg"/>
          <div id="connect--header"></div>
        </div>
        <div className="text-container">
          <div className="intro">
            <h1>Провайдер. Партнер. Друг.</h1>
            <p>
              Мы предлагаем провайдерские и информационные услуги для бизнеса на индивидуальных условиях в аэропорту  Шереметьево и прилегающим к ним территориям.
            </p>
            <div className="buttons-container">
              <a href="#principles">Принципы</a><a href="#tariffs">Тарифы</a>
            </div>
          </div>
          <div className="connect-section">
            <div id="principles" className="anchor-link"></div>
            <h2>Принципы</h2>

            <div className="grid">
              <div>
                <h3>Клиент во главе угла</h3>
                <p>
                  Выгодные тарифы, кратчайший срок от заключения контракта до его реализации. Никакой лишней бюрократии, только то, что касается дела.
                </p>
              </div>
              <div>
                <h3>Надёжно, как швейцарские часы</h3>
                <p>
                  Повышение отказоустойчивости узлов за счёт резервирования. Круглосуточная поддержка, реализуемая опытными инженерами. Современные системы защиты и хранения информации, качество и точность в исполнении обязательств отлаженная, как в механизме швейцарских часов.
                </p>
              </div>
              <div>
                <h3>Открыты для сотрудничества</h3>
                <p>
                  Партнёрские программы монетизации сети для владельцев недвижимости. Простые решения и прозрачные правила: Вы получаете интернет для своих нужд по особо выгодной цене и, кроме того, получаете от 10% с оборота на своём объекте в виде агентского вознаграждения.
                </p>
              </div>
            </div>

          </div>
          <div className="connect-section">
            <div id="tariffs"  className="anchor-link"></div>
            <h2>Тарифы</h2>

            <div className="grid">
              <div>
                <h3>Кофе с собой</h3>
                <span className="speed">Скорость <span>5-10 mb/s</span></span>
                <p>
                  Оптимальный вариант для организации работы системы оплаты картами и программного обеспечения в точках осуществления продаж.
                </p>
                <span className="price">10 000₽ <span>в месяц</span></span>
              </div>
              <div>
                <h3>Duty Free</h3>
                <span className="speed">Скорость <span>5-10 mb/s</span></span>
                <p>
                  У вас есть полноценный магазин с учётной системой и несколькими кассами? Отлично, потому-что у нас есть решение для организации слаженной работы.
                </p>
                <span className="price">22 500₽ <span>в месяц</span></span>
              </div>
              <div>
                <h3>Кафе с посадкой</h3>
                <span className="speed">Скорость <span>5-10 mb/s</span></span>
                <p>
                  Оптимальный вариант для организации работы системы оплаты картами и программного обеспечения в точках осуществления продаж.
                </p>
                <span className="price">35 000₽ <span>в месяц</span></span>
              </div>
              <div>
                <h3>Ресторан в лаундж зоне</h3>
                <span className="speed">Скорость <span>5-10 mb/s</span></span>
                <p>
                  Оптимальный вариант для организации работы системы оплаты картами и программного обеспечения в точках осуществления продаж.
                </p>
                <span className="price">45 000₽ <span>в месяц</span></span>
              </div>
              <div>
                <h3>Отель или офис</h3>
                <span className="speed">Скорость <span>5-10 mb/s</span></span>
                <p>
                  Оптимальный вариант для организации работы системы оплаты картами и программного обеспечения в точках осуществления продаж.
                </p>
                <span className="price">55 000₽ <span>в месяц</span></span>
              </div>
            </div>
          </div>
          <div className="connect-section">
            <div id="principles" className="anchor-link"></div>
            <h2>Арендодателям</h2>

            <div className="grid">
              <div>
                <h3>Бизнесу нужно не только помещение</h3>
                <p>
                  Если вы являетесь собственником недвижимости и сдаёте её в аренду, то мы можем предложить партнёрские программы, позволяющие вам повысить привлекательность ваших помещений и зарабатывать на услугах предоставления сети арендаторам.
                </p>
              </div>
            </div>
          </div>
          <Form formId="connect-form" className="connect-aside"/>
        </div>
      </section>
    )
  }
}
