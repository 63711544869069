import React, { Component } from 'react';

export default class Product1 extends Component{
  render() {
    return(
      <div className="product product-1">
        <div className="product--about">
          <div id="rellaty" className="anchor-link"></div>
          <img alt="" src="/images/products/rellaty.svg"/>
          <h2>Связь по вашим правилам</h2>
          <p>
            Облачный масштабируемый контакт-центр может использоваться как для построения корпоративного инструмента взаимодейтсвия с клиентами так и профессинальными игроками телекоммуникационного рынка для предоставления облачных услуг.
          </p>
        </div>

        <div className="product--section">
          <h2>Описание</h2>
          <div className="grid">
            <div>
              <h3>Ни больше, ни меньше</h3>
              <p>
              Расширяйте, а не заменяйте. Система горизонтально масштабируется простым модульным расширением. Это значит, что вы можете обойтись без существенных инвестиций на старте проекта, расширяя контакт-центр по мере необходимости*.
              </p>
            </div>
            <div>
              <h3>Как швейцарские часы</h3>
              <p>
              Надёжность — один из главных факторов для контакт-центра. Все компоненты системы зарезервированы и могут быть географически распределены по нескольким ЦОДам, что делает систему максимально отказоустойчивой**.
              </p>
            </div>
            <div>
              <h3>Удобно со всех сторон</h3>
              <p>
                Обращения по телефону, мессенджерам, электронной почте и т.д. проходят в рамках единой очереди.
              </p>
            </div>
            <div>
              <h3>И в любом формате</h3>
              <p>
                Гибко настраиваемая система отчетности позволяет получить любой срез исторической статистики.
              </p>
            </div>
            <div>
              <h3>Как на ладони</h3>
              <p>
              Инструменты контроля персонала, real-time мониторинг, запись разговоров и запись экранов операторов в удобном формате приложения.
              </p>
            </div>
            <div className="double-h3">
              <h3>Интеграция с любыми CRM</h3>
              <h3>Система исходящего обзвона</h3>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
