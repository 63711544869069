import React, { Component } from 'react';

export default class Title extends Component{
  render() {
    return(
      <section id="title">
        <div className="intro-container">
          <img alt="" src="images/ship.svg" />
          <div id="header-animation"></div>
          <p>
            Открывается в бизнесе каждый день. Наша команда помогает придерживаться верного курса и достигать целей за счёт надёжных и выверенных решений. WSL — компания, реализующая It-проекты на всех стадиях цикла: от концептуальной, до процесса внедрения. 
          </p>
        </div>
      </section>
    )
  }
}
