import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import Title from './title.component';
import InfoCards from './infocards.component';
import Solutions from './solutions.component';
import Connect from './connect.component';
import Form from './form/form.component';

export default class Main extends Component{

  render() {
    return(
      <main id="content" className={ this.props.className }>
        <Route exact path="/" component={ Title }/>
        <Route exact path="/" component={ InfoCards }/>
        <Route path="/solutions" component={ Solutions }/>
        <Route path="/connect" component={ Connect }/>
        <div id="corners-bottom" className="corners"></div>
        <div className="corners-shadow"></div>
        <Route path="/connect" render={ () => <Form formId="footer-form" className="footer-form-container"/> }/>
      </main>
    )
  }
}
