import React, { Component } from 'react';

export default class Form extends Component{

  state = {
    name: '',
    company: '',
    phone_or_mail: ''
  }

  handleChange = e => {
    this.setState({
      [e.currentTarget.name] : e.currentTarget.value
    })
  }

  submitForm = e => {
    let notice = 'Пожалуйста, заполните все поля контактной формы.'

    const {
      name,
      company,
      phone_or_mail
    } = this.state

    if( name && company && phone_or_mail ){

      let xhr = new XMLHttpRequest();

      /*if success*/
      xhr.onload = () => {
        const buttons = document.getElementsByTagName('button');

        let changeText = (element) => element.innerHTML = 'Отправлено'

        let fadeIn = (element) => element.style.opacity = 1

        for(let i = 0; i < buttons.length; i++ ){
          buttons[i].style.opacity = 0;
          buttons[i].style.pointerEvents = 'none';
          setTimeout( () => changeText(buttons[i]), 250 )
          setTimeout( () => fadeIn(buttons[i]), 750 )
        }
      }

      /*sending request*/
      xhr.open('get',
       'https://form.wsl.group/index.php?' +
       '&name=' + this.state.name +
       '&company=' + this.state.company +
       '&contact_info=' + this.state.phone_or_mail
       , true);
      xhr.send();
    }else{
      alert(notice);
    }

    e.preventDefault();
  }

  render() {
    const {
      name,
      company,
      phone_or_mail
    } = this.state

    return(
      <div className={this.props.className}>
        <h3>Интересно?</h3>
        <p>
          Тогда свяжитесь с нами сами или оставьте свои контакты для связи.
        </p>
        <span>
          Questions@wsl.group
        </span>
        <span>
          +7 (495) 369 24 22
        </span>
        <form id={this.props.formId} onSubmit={this.submitForm}>
          <input type="text" name="name" placeholder="Ваше имя" value={name} onChange={this.handleChange} />
          <hr/>
          <input type="text" name="company" placeholder="компания" value={company} onChange={this.handleChange} />
          <hr/>
          <input type="text" name="phone_or_mail" placeholder="почта или телефон" value={phone_or_mail} onChange={this.handleChange} />
          <hr/>
          <button type="submit" value="Submit">Отправить</button>
        </form>
      </div>
    )
  }
}
