import React, { Component } from 'react';

export default class InfoCards extends Component{

  componentDidMount() {

      const container = this.refs.info_cards
      let containerHeight;
      let right_grid = document.getElementById('info-cards').getElementsByClassName('grid-right')[0]
      let html = document.documentElement

      let scrolled, boundContainer, boundHtml, clientHeight;

    function paralax(scrolled, containerHeight, vw, k) {
      right_grid.style.top = -scrolled / vw / k + 'vw';
      container.style.height = containerHeight - scrolled / vw / k + 'vw';
    };

    window.addEventListener('scroll', () => {
      scrolled = window.scrollY;

      if( document.documentElement.clientWidth > 1024 ){
        containerHeight = 61.45833;
        paralax(scrolled, containerHeight, 19.2, 4);
      }else if( document.documentElement.clientWidth >= 768){
        containerHeight = 88.54167;
        paralax(scrolled, containerHeight, 76.8, 1);
      }

    })
  }

  render() {
    return(
      <section id="info-cards" ref="info_cards">
        <div className="grid-left">
          <div className="info-card">
            <div className="info-card--title">
              <img alt="" src="/images/connect.svg"/>
              <span>Широкополосный доступ в интернет и аренда каналов связи.</span>
            </div>
            <div className="info-card--content">
              <h1>Провайдер.<br/>Партнер. Друг.</h1>
              <p>
                Мы предлагаем провайдерские и информационные услуги для бизнеса на индивидуальных условиях в аэропорту  Шереметьево и прилегающим к ним территориям.
              </p>
              <h2>Решения</h2>
              <a href="/connect#tariff">Провайдинг в Шереметьево</a>
            </div>
          </div>
        </div>

        <div className="grid-right">
          <div className="info-card">
              <div className="info-card--title">
                <img alt="" src="/images/solutions.svg"/>
                <span>Разработчик и интегратор корпоративных решений в области связи.</span>
              </div>
              <div className="info-card--content">
                <h1>Будьте на связи.<br className="br"/>Всегда.</h1>
                <p>
                  А мы позаботимся об этом: просто выберите один из продуктов ниже и узнай-те, как он изменит или обезопасит ваше дело. Быстро, гибко и эффективно.
                </p>
                <h2>Решения</h2>
                <a href="/solutions#rellaty">Rellaty</a><a href="/solutions#sip">Smart SIP gateway</a>
              </div>
            </div>
        </div>
      </section>
    )
  }
}
