import React, { Component } from 'react';
import Product1 from './solutions/product_1.component';
import Product2 from './solutions/product_2.component';

export default class Solutions extends Component{
  render() {
    return(
      <section id="solutions">
        <div class="logo">
          <img alt="" src="/images/ship.svg"/>
          <div id="solutions--header"></div>
        </div>
        <div class="text-container">
          <div class="intro">
            <h2>Будьте на связи. Всегда.</h2>
            <p>
            А мы позаботимся об этом: просто выберите один из продуктов ниже и узнайте, как он изменит или обезопасит ваше дело. Быстро, гибко и эффективно.
            <br/><br/>
            White Sails Solutions — разработчик и интегратор корпоративных решений в области связи.
            </p>
            <div className="buttons-container">
              <a href="#rellaty">Rellaty</a>
              <a href="#sip">Smart SIP gateway</a>
            </div>
          </div>
          <Product1/>
          <Product2/>
          <div className="partners">
            <h2>Наши партнеры</h2>
            <img alt="" src="/images/partners/yealink.svg"/> <img alt="" src="/images/partners/audiocodes.svg"/>
          </div>
        </div>
      </section>
    )
  }
}
